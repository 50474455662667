import { Typography } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { LinearProgress } from "@material-ui/core";

const Header = (): JSX.Element => {
return (
    <Grid direction="column" spacing={5}>
        <Typography variant="h5" color="gray" style={{ lineHeight: 0.8 }}>
            State Tool - Access Key Client
        </Typography>
        <Typography variant="subtitle1" color="gray" style={{ lineHeight: 2.5, paddingTop: 18 }}>
            This page is used to generate an access key for the Journal Modifier Tool.
            <ul>
                <li>Enter your location ID and click the "Get Access Key" button to generate an access key.</li>
                <li>The access key will be displayed in the box below.</li>
                <li>Use Copy To Clipboard button to copy the access key.</li>
            </ul>            
        </Typography>
    </Grid>
    );
};

export default Header;