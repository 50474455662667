import { useState, ChangeEvent } from "react";
import { Grid, Button, TextField, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CopyToClipboardButton from "../../../shared/copyToClipboardButton";

type PropTypes = {
    getAccessKeyApi: () => void;
    setLocationId: (locId: string) => void;
    isLocationIdValid: boolean;
    currentLocationId: string;
    currentAccessKey: string;
};

const Body = ({ getAccessKeyApi, setLocationId, currentLocationId, currentAccessKey, isLocationIdValid }: PropTypes): JSX.Element => {

    const [showCopyToClipboardButton, setShowCopyToClipboardButton] = useState<boolean>(true);

    const buttonTheme = createTheme({ palette: { primary: { main: "#4A90E2" } } });    

    const onLocationIdChange = (e: ChangeEvent<HTMLInputElement>) => {
        const locationId = e.target.value;
        setLocationId(locationId);
    };

    return (
        <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="stretch"
            alignItems="stretch"
            paddingTop={2}>

            <Grid item xl={10} lg={10} md={10} xs={10} container direction="row">
                <TextField
                    type="text"
                    required
                    error={!isLocationIdValid}
                    helperText={!isLocationIdValid ? "Invalid Location Id" : ""}
                    value={currentLocationId}
                    label="Location Id"
                    onChange={onLocationIdChange}
                    style={{ paddingRight: 15, width:500, height:50 }}
                />
                <ThemeProvider theme={buttonTheme}>
                    <Button
                        type="button"
                        onClick={getAccessKeyApi}
                        variant="contained"
                        style={{
                            width: 200,
                            height: 50,
                            fontSize: 12,
                            float: "right",
                            verticalAlign: "stretch"
                        }}>
                        Get Access Key
                    </Button>
                </ThemeProvider>
            </Grid>

            <Grid item xl={10} lg={10} md={10} xs={10} container direction="column" marginTop={5}>
                <Typography variant="subtitle2" color="gray">
                    Access Key
                </Typography>

                {currentAccessKey ? (
                    <Grid container direction="row">
                        <TextField
                            type="text"
                            fullWidth
                            value={currentAccessKey}
                            variant="filled"
                            style={{ paddingRight: 15, width: 650, height: 50 }}
                            InputProps={{
                                disabled: true,
                            }} />
                        <CopyToClipboardButton
                            text={currentAccessKey}
                            show={showCopyToClipboardButton}
                        />
                    </Grid>
                ) : (
                    <Grid container direction="row">
                        <TextField
                            type="text"
                            fullWidth
                            variant="filled"
                            style={{ paddingRight: 15, width: 650, height: 50, fontSize: 18 }}
                            InputProps={{
                                disabled: true,
                            }} />
                        <CopyToClipboardButton
                            text=""
                            show={showCopyToClipboardButton}
                        />
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};
export default Body;