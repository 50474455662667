import HttpClient from "./HttpClient";
import { AxiosRequestConfig } from "axios";
import { AccessKey } from "../types";

export type Options = {
  baseUrl: string;
  getAccessTokenAsync: () => Promise<string>;
};

export default class AccessKeyService extends HttpClient {
  private getAccessTokenAsync: () => Promise<string>;

  public constructor({ baseUrl, getAccessTokenAsync }: Options) {
    super(baseUrl);
    this.getAccessTokenAsync = getAccessTokenAsync;
    this.initializeRequestInterceptor();
  }

  private initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(
      this.handleRequest,
      this.handleError
    );
  };

  private handleRequest = async (config: AxiosRequestConfig) => {
    const { headers, ...rest } = config;

    const accessToken = await this.getAccessTokenAsync();
    headers.Authorization = accessToken;
    headers.Accept = "application/json";

    return {
      headers,
      ...rest,
    };
  };

  public getAccesKey = (locationId: string): Promise<string> => {
    return this.instance.get(`/${locationId}/accesskey`);
  };
}
