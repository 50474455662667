import { CookiesProvider } from "react-cookie";
import App from "./App";

const StateTool = (): JSX.Element => (
    <CookiesProvider>
        <App />
    </CookiesProvider>
);

export default StateTool;
