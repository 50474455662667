import AccessKeyService from "../../api/AccessKeyService";
import { Container, Box } from "@mui/material";
import AccessKeyClient from "./AccessKeyClient";

type PropTypes = {
    accessKeyService: AccessKeyService;
};

const GetAccessKeyClient = ({ accessKeyService }: PropTypes): JSX.Element => {
    return (
        <Container maxWidth="lg">
            <AccessKeyClient accessKeyService={accessKeyService} />
        </Container>
    );
};

export default GetAccessKeyClient;