import AccessKeyService from "../../api/AccessKeyService";
import { Grid } from "@mui/material";
import { LinearProgress } from "@material-ui/core";
import { useState } from "react";

import Header from "../Header";
import Body from "../Body";

type PropTypes = {
    accessKeyService: AccessKeyService;
};

const AccessKeyClient = ({ accessKeyService }: PropTypes): JSX.Element => {

    const [showProgress, setShowProgress] = useState<boolean>(false);
    const [locationId, setLocationId] = useState<string>("");
    const [accessKey, setAccessKey] = useState<string>("");
    const [isLocationIdValid, setIsLocationIdValid] = useState<boolean>(true);

    const SetLocationId = (locId: string) => {
        setLocationId(locId);
        verifyLocation(locId);
    }

    const verifyLocation = (locId) => {
        var regEx = new RegExp("^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$");
        if (locId === "" || !regEx.test(locId)) {
            setIsLocationIdValid(false);
        }
        else {
            setIsLocationIdValid(true);
        }
    }

    const getAccessKeyApi = async () => {

        if (isLocationIdValid == false) return;

        if (locationId === "") {
            setIsLocationIdValid(false);
            return;
        }

        setLocationId(locationId);
        setShowProgress(true);
        accessKeyService.getAccesKey(locationId).then((response) => {
            setShowProgress(false);
            setAccessKey(response);
        }).catch((error) => { 
            setShowProgress(false);
            setAccessKey(error.message);
         });
    };

    return (
        <Grid marginLeft={35}>
            {showProgress ? (
            <Grid>
                <LinearProgress />
                <Grid
                    container
                    direction="column"
                    justifyContent="stretch"
                    alignItems="start"
                    paddingTop={2}>
                    <Header />
                    <Body
                        getAccessKeyApi={getAccessKeyApi}
                        setLocationId={SetLocationId}
                        currentLocationId={locationId}
                        currentAccessKey={accessKey}
                        isLocationIdValid={isLocationIdValid} />
                </Grid>
            </Grid>
            ) : (
                    <Grid
                        container
                        direction="column"
                        justifyContent="stretch"
                        alignItems="start"
                        paddingTop={2}>
                        <Header />
                        <Body
                            getAccessKeyApi={getAccessKeyApi}
                            setLocationId={SetLocationId}
                            currentLocationId={locationId}
                            currentAccessKey={accessKey}
                            isLocationIdValid={isLocationIdValid}/>
                    </Grid>
             )}
        </Grid>
    );
};

export default AccessKeyClient;