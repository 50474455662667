import { useCookies } from "react-cookie";
import { ThemeProvider } from "@mui/material";
import AccessKeyService from "./api/AccessKeyService";
import GetAccessKeyClient from "./views/getAccessKeyClient";
import theme from "./theme";

// We are ts ignoring this because its shared by the container app
// @ts-ignore
import { AuthTokenManager } from "@par/support-portal-auth";

const App = (): JSX.Element => {
  const [cookies] = useCookies(["name", "username"]);

  if (!cookies.username) {
    window.location.href = "/?returnUrl=/statetool";

    return <></>;
  }

  const baseUrl = process.env.STATETOOL_SERVICE_BASEURL;
  const accessKeyService = new AccessKeyService({
    baseUrl,
    getAccessTokenAsync: () => AuthTokenManager.getTokenAsync(),
  });

  return (
    <ThemeProvider theme={theme}>
      <GetAccessKeyClient accessKeyService={accessKeyService} />
    </ThemeProvider>
  );
};

export default App;
